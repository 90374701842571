import React, { useContext, useEffect, useState } from "react";
import MenuHeader from "../../components/Header/MenuHeader";
import HomeActions from "./HomeActions/HomeActions";
import { Grid, Typography } from "@mui/material";
import HomeTranscript from "./HomeContent/HomeTranscript";
import HomeResponse from "./HomeContent/HomeResponse";
import Conversation from "../../components/Cards/Conversation";
import { SocketContext } from "../../services/SocketContext";
import ReviewModal from "../../components/Modals/ReviewModal";
import { useLocation, useParams } from "react-router-dom";
import InfoModal from "../../components/Modals/InfoModal";
import useGlobal from "../../hooks/useGlobal";
import { GlobalContext } from "../../services/GlobalContext";
import FeedbackModal from "../../components/Modals/FeedbackModal";
import LanguageSelection from "../../components/Modals/LanguageSelection";
import useWhisper2 from "../../hooks/useWhisper2";
import { useTranslation } from "react-i18next";
import MicSelection from "../../components/Modals/MicSelection";

const HomeScreen = () => {
  const location = useLocation();
  const { t } = useTranslation();
  // Access the sessionId parameter from the URL
  const { sessionId } = useParams();
  const {
    endAlert,
    setEndAlert,
    session,
    receiverSpeaking,
    isSpeaking,
    user,
    processing,
    receiver,
    type,
  } = useContext(SocketContext);
  const {
    subscription,
    languageSetting,
    setLanguageSetting,
    endCall,
    setEndCall,
    setIsTranslation,
  } = useContext(GlobalContext);
  const { getTranscripts, getSession, validateSubscription } = useGlobal();
  // const [endCall, setEndCall] = useState(false);
  const [feedbackAlert, setFeedbackAlert] = useState(false);
  const [micModal, setMicModal] = useState(false);
  const {
    microphones,
    defaultMicrophone,
    setDefaultMicrophone,
    onMicStopSpeaking,
    onMicStartSpeaking,
  } = useWhisper2({
    sessionId,
  });

  const isValidSubscription = validateSubscription(subscription);

  const isCompleted = session?.sessionStatus === "COMPLETED";

  const isTranslation =
    type === "Provider" ? receiver?.language !== "en" : user?.language !== "en";

  useEffect(() => {
    setIsTranslation(isTranslation);
  }, [isTranslation]);

  const onCloseReview = () => {
    setEndCall(false);
  };

  useEffect(() => {
    getSession({ sessionId, search: location.search });
    getTranscripts({ sessionId });
  }, []);

  useEffect(() => {
    if (!isCompleted && microphones?.length > 1) {
      setMicModal(true);
    }
  }, [isCompleted, microphones]);

  return (
    <div>
      <MenuHeader />
      <HomeActions
        startRecording={() => {
          // startRecording()
          onMicStartSpeaking();
        }}
        stopRecording={() => {
          // stopRecording();
          onMicStopSpeaking();
        }}
        endCall={endCall}
        setEndCall={setEndCall}
        isValidSubscription={isValidSubscription}
        languagePopup={languageSetting}
        isTranslation={isTranslation}
      />
      <Grid container className="d-flex px-4">
        <Grid item xs={12} sm={6}>
          {/* <HomeTranscript/> */}
        </Grid>
        <Grid item xs={12} sm={6}>
          <HomeResponse
            isValidSubscription={isValidSubscription}
            isCompleted={isCompleted}
          />
        </Grid>
      </Grid>
      {/* {isTranslation && ( */}
      <Conversation
        transcript={{ loading: true }}
        userId={user?.userId}
        isValidSubscription={isValidSubscription}
      />
      {/* )} */}

      {processing && (
        <div className="mx-3 my-2 bg-white position-fixed bottom-0">
          <Typography className="bold-600 font-18">
            {t("homeAction.processing")}
          </Typography>
        </div>
      )}
      {endCall && <ReviewModal open={endCall} handleClose={onCloseReview} />}
      {endAlert && (
        <InfoModal
          open={endAlert}
          handleClose={() => {
            setEndAlert(false);
            setFeedbackAlert(true);
          }}
          title={t("homeAction.callHasEnded")}
          subtext={t("homeAction.callEndText")}
        />
      )}
      {receiverSpeaking && isSpeaking && (
        <InfoModal
          open={receiverSpeaking && isSpeaking}
          handleClose={() => {}}
          title={t("homeAction.crossTalk")}
          subtext={t("homeAction.crossTalkText")}
        />
      )}
      {feedbackAlert && (
        <FeedbackModal
          open={feedbackAlert}
          handleClose={() => {
            setFeedbackAlert(false);
          }}
          translationEnabled={receiver?.language !== "en"}
        />
      )}
      {languageSetting && (
        <LanguageSelection
          open={languageSetting}
          handleClose={() => {
            setLanguageSetting(false);
          }}
          sessionId={sessionId}
          type={type}
        />
      )}
      {micModal && (
        <MicSelection
          open={micModal}
          handleClose={() => {
            setMicModal(false);
          }}
          defaultMicrophone={defaultMicrophone}
          setDefaultMicrophone={setDefaultMicrophone}
          microphones={microphones}
        />
      )}
    </div>
  );
};

export default HomeScreen;
